import request from '@/utils/request'
import axios from 'axios'
import { MedicalBusinessTermOfService } from '@/interface/api'

const prefix = '/api/v0/customer/medical-businesses/terms-of-service'

export const retrieveTermsOfService = (
  medicalBusinessId: string
): Promise<MedicalBusinessTermOfService> =>
  request({
    url: `${prefix}/${medicalBusinessId}/`,
    method: 'get'
  }).then(response => response.data.data.attributes)

export const getTermsOfServiceHtml = (url: string): Promise<string> =>
  axios
    .get(url)
    .then(response => response.data)
    .catch(() => false)
